import type { ReactElement } from 'react';
import { useLayoutEffect, useRef } from 'react';
import { Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { MarkdownTypography } from '../../../../components/typography/MarkdownTypography';
import { IbanLargeCube } from './IbanLargeCube';
import { IbanSmallCube } from './IbanSmallCube';

gsap.registerPlugin(ScrollTrigger);

const StepsContainer = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  flexDirection: 'column',
  height: '100%',
  inset: 0,
  justifyContent: 'center',
  position: 'absolute',
  textAlign: 'center',
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    '.text-1': {
      bottom: 0,
      left: '10%',
    },
    '.text-2': {
      left: '20%',
      top: 0,
    },
    '.text-3': {
      bottom: 0,
      left: 0,
      margin: 'auto',
      right: 0,
    },
    '.text-4': {
      right: '20%',
      top: 0,
    },
    '.text-5': {
      bottom: 0,
      right: '10%',
    },
    span: {
      position: 'absolute',
      width: '150px',
    },
  },
}));

const StyledSection = styled('section')(({ theme }) => ({
  alignItems: 'center',
  backgroundImage: 'linear-gradient(180deg, #FFFFFF 0%, #F1F3FE 80%, #FFFFFF 100%)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingTop: theme.spacing(10),
  width: '100%',
}));

const StyledTriggerContainer = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  height: '600px',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    height: '500px',
  },
  [theme.breakpoints.down('sm')]: {
    height: '100%',
  },
}));

const StyledCubesContainer = styled(Stack)(({ theme }) => ({
  alignItems: 'flex-end',
  flexDirection: 'row',
  height: '100%',
  justifyContent: 'center',
  overflow: 'hidden',
  padding: theme.spacing(8, 0),
  position: 'relative',
  width: '100%',
  [theme.breakpoints.down('lg')]: {
    alignItems: 'center',
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0),
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

type PpIbanSection = {
  step1?: string | null;
  step2?: string | null;
  step3?: string | null;
  step4?: string | null;
  step5?: string | null;
  title?: string | null;
};

export function IbanSection({
  step1,
  step2,
  step3,
  step4,
  step5,
  title,
}: PpIbanSection): ReactElement {
  const triggerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const tl = gsap.timeline({
        scrollTrigger: {
          end: () => '+=400',
          scrub: 3,
          start: () => 'top center',
          trigger: triggerRef.current,
        },
      });
      tl.to('.text-1', { color: '#383838' });
      tl.fromTo('.box-1', { filter: 'brightness(1)' }, { filter: 'brightness(1.5)' });
      tl.to('.box-1', { filter: 'brightness(1)' });
      tl.to('.text-2', { color: '#383838' });
      tl.fromTo('.box-2', { filter: 'brightness(1)' }, { filter: 'brightness(1.5)' });
      tl.to('.box-2', { filter: 'brightness(1)' });
      tl.to('.text-3', { color: '#383838' });
      tl.to('.text-4', { color: '#383838' });
      tl.fromTo('.box-3', { filter: 'brightness(1)' }, { filter: 'brightness(1.5)' });
      tl.to('.box-3', { filter: 'brightness(1)' });
      tl.to('.text-5', { color: '#383838' });
    }, triggerRef);

    return () => ctx.revert();
  }, [triggerRef]);

  return (
    <StyledSection>
      <StyledTriggerContainer ref={triggerRef}>
        <MarkdownTypography textAlign="center" variant="h5">
          {title}
        </MarkdownTypography>
        <StyledCubesContainer>
          <IbanLargeCube
            className="box-1"
            mobileStyle={{
              left: '-40%',
            }}
          />
          <IbanLargeCube
            className="box-2"
            mobileStyle={{
              bottom: 0,
              margin: 'auto',
              right: '-40%',
              top: 0,
            }}
          />
          <IbanLargeCube
            className="box-3"
            mobileStyle={{
              bottom: '0',
              left: '-40%',
            }}
          />
          <IbanSmallCube bottom={18} left="0" width="10%" />
          <IbanSmallCube bottom={8} left="4%" width="15%" />
          <IbanSmallCube bottom={12} left="25%" width="10%" />
          <IbanSmallCube bottom={18} left="90%" width="10%" />
          <IbanSmallCube bottom={8} left="80%" width="15%" />
          <StepsContainer spacing={4}>
            <Typography
              className="text-1"
              color="text.light"
              component="span"
              variant="paragraphBodyLBold"
            >
              {step1}
            </Typography>
            <Typography
              className="text-2"
              color="text.light"
              component="span"
              variant="paragraphBodyLBold"
            >
              {step2}
            </Typography>
            <Typography
              className="text-3"
              color="text.light"
              component="span"
              variant="paragraphBodyLBold"
            >
              {step3}
            </Typography>
            <Typography
              className="text-4"
              color="text.light"
              component="span"
              variant="paragraphBodyLBold"
            >
              {step4}
            </Typography>
            <Typography
              className="text-5"
              color="text.light"
              component="span"
              variant="paragraphBodyLBold"
            >
              {step5}
            </Typography>
          </StepsContainer>
        </StyledCubesContainer>
      </StyledTriggerContainer>
    </StyledSection>
  );
}
