import { useEffect } from 'react';
import { navigate, useLocation } from '@gatsbyjs/reach-router';
import type { PageProps } from 'gatsby';
import { graphql } from 'gatsby';
import type {
  HomepageQuery,
  MarkdownRemarkFrontmatterAdvantages,
  MarkdownRemarkFrontmatterCards,
  MarkdownRemarkFrontmatterCurrencies,
  MarkdownRemarkFrontmatterPartners,
} from '../../generated/types';
import { navigateToLocalizedPath } from '../components/i18n/utils';
import { HomepageTemplate } from '../components/templates';
import { AdvantagesSection } from '../modules/homepage/sections/advantages-section/AdvantagesSection';
import { ApiSection } from '../modules/homepage/sections/api-section/ApiSection';
import { CtaSection } from '../modules/homepage/sections/cta-section/CtaSection';
import { CurrenciesSection } from '../modules/homepage/sections/currencies-section/CurrenciesSection';
import { FeaturesSection } from '../modules/homepage/sections/features-section/FeaturesSection';
import { HeroSection } from '../modules/homepage/sections/hero-section/HeroSection';
import { IbanSection } from '../modules/homepage/sections/iban-section/IbanSection';
import { PartnersSection } from '../modules/homepage/sections/partners-section/PartnersSection';

type PpHomepage = PageProps<HomepageQuery>;

function Homepage({ data: { content, currencies } }: PpHomepage): React.ReactElement {
  const location = useLocation();

  useEffect(() => {
    navigateToLocalizedPath(location.pathname, navigate);
  }, [location.pathname]);

  return (
    <HomepageTemplate backgroundColor="transparent" seo={content?.frontmatter?.seo}>
      <HeroSection
        button={content?.frontmatter?.heroButton}
        image={content?.frontmatter?.heroImage}
        subtitle={content?.frontmatter?.heroSubtitle}
        title={content?.frontmatter?.heroTitle}
      />
      <PartnersSection
        partners={content?.frontmatter?.partners as Array<MarkdownRemarkFrontmatterPartners>}
      />
      <FeaturesSection
        features={content?.frontmatter?.cards as Array<MarkdownRemarkFrontmatterCards>}
        textWithIcon={content?.frontmatter?.featuresTextWithIcon}
        title={content?.frontmatter?.featuresTitle}
      />
      <IbanSection
        step1={content?.frontmatter?.cubesStep1}
        step2={content?.frontmatter?.cubesStep2}
        step3={content?.frontmatter?.cubesStep3}
        step4={content?.frontmatter?.cubesStep4}
        step5={content?.frontmatter?.cubesStep5}
        title={content?.frontmatter?.cubesTitle}
      />
      <CurrenciesSection
        copy={content?.frontmatter?.currenciesCopy}
        currencies={
          currencies?.frontmatter?.currencies as Array<MarkdownRemarkFrontmatterCurrencies>
        }
        subtitle={content?.frontmatter?.currenciesSubtitle}
        title={content?.frontmatter?.currenciesTitle}
      />
      <ApiSection
        image1={content?.frontmatter?.image1}
        image2={content?.frontmatter?.image2}
        image3={content?.frontmatter?.image3}
        image4={content?.frontmatter?.image4}
        image5={content?.frontmatter?.image5}
        title={content?.frontmatter?.apiTitle}
      />
      <AdvantagesSection
        advantages={content?.frontmatter?.advantages as Array<MarkdownRemarkFrontmatterAdvantages>}
        copy={content?.frontmatter?.advantagesSubtitle}
        textWithIcon={content?.frontmatter?.featuresTextWithIcon}
        title={content?.frontmatter?.advantagesTitle}
      />
      <CtaSection
        background={content?.frontmatter?.ctaBackground}
        button={content?.frontmatter?.ctaButton}
        ctaCopy={content?.frontmatter?.ctaSubtitle}
        ctaTitle={content?.frontmatter?.ctaTitle}
      />
    </HomepageTemplate>
  );
}

export const query = graphql`
  query Homepage($locale: String!) {
    content: markdownRemark(frontmatter: { layout: { eq: "homepage" }, locale: { eq: $locale } }) {
      frontmatter {
        seo {
          title
          description
          image
        }
        heroTitle
        heroSubtitle
        heroButton
        heroImage {
          src
          alt
        }
        partners {
          id
          logo
          name
        }
        cards {
          id
          title
          subtitle
        }
        featuresTitle
        featuresTextWithIcon
        cubesTitle
        cubesStep1
        cubesStep2
        cubesStep3
        cubesStep4
        cubesStep5
        currenciesTitle
        currenciesSubtitle
        currenciesCopy
        advantagesTitle
        advantagesSubtitle
        advantages {
          id
          title
          subtitle
          image
        }
        apiTitle
        image1 {
          src
          alt
        }
        image2 {
          src
          alt
        }
        image3 {
          src
          alt
        }
        image4 {
          src
          alt
        }
        image5 {
          src
          alt
        }
        ctaTitle
        ctaSubtitle
        ctaButton
        ctaBackground
      }
    }
    currencies: markdownRemark(frontmatter: { layout: { eq: "currencies" } }) {
      frontmatter {
        currencies {
          code
          flag
        }
      }
    }
  }
`;

export default Homepage;
