import { lazy, Suspense } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { isBrowser } from '@noah-labs/fe-shared-util-shared';
import Marquee from 'react-fast-marquee';
import type { MarkdownRemarkFrontmatterCurrencies } from '../../../../../generated/types';
import { useBreakpoints } from '../../../../components/hooks/useBreakpoints';
import { GrowInViewport } from '../../../../components/layout/GrowInViewport';
import { HomepageSection } from '../../../../components/layout/HomepageSection';
import { MarkdownTypography } from '../../../../components/typography/MarkdownTypography';

const LazyCurrenciesGlobe = lazy(() => import('./CurrenciesGlobe'));

type PpCurrenciesSection = {
  copy?: string | null;
  currencies?: Array<MarkdownRemarkFrontmatterCurrencies>;
  subtitle?: string | null;
  title?: string | null;
};

const StyledFlag = styled('img')(({ theme }) => ({
  filter: 'drop-shadow(0px 8px 8px rgba(0, 0, 0, 0.1))',
  margin: theme.spacing(2),
  width: '40px',
}));

export function CurrenciesSection({
  copy,
  currencies,
  subtitle,
  title,
}: PpCurrenciesSection): React.ReactElement | null {
  const { isExtraSmall } = useBreakpoints();

  return (
    <HomepageSection sx={{ overflow: 'hidden' }}>
      <Grid container>
        <Grid item lg={6} xs={12}>
          <GrowInViewport timeout={1000}>
            <Stack justifyContent="center" sx={{ height: '100%' }}>
              <div>
                <Typography color="text.secondary" variant="h5">
                  {title}
                </Typography>
                <MarkdownTypography gutterBottom variant="h5">
                  {subtitle}
                </MarkdownTypography>
              </div>
              <Typography variant={isExtraSmall ? 'bodyHeadingXS' : 'subHeadingS'}>
                {copy}
              </Typography>
              <Marquee speed={25}>
                {currencies?.map((currency) => {
                  if (!currency.code || !currency.flag) {
                    return null;
                  }
                  return <StyledFlag key={currency.code} alt={currency.code} src={currency.flag} />;
                })}
              </Marquee>
            </Stack>
          </GrowInViewport>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Stack
            alignItems="center"
            className="globe-container"
            justifyContent="center"
            sx={{
              height: '600px',
              position: 'relative',
              width: '100%',
            }}
          >
            {isBrowser() && (
              <Suspense fallback={null}>
                {/* https://www.gatsbyjs.com/docs/using-client-side-only-packages/#workaround-3-load-client-side-dependent-components-with-react-loadable */}
                <LazyCurrenciesGlobe />
              </Suspense>
            )}
            {/* Canvas overlay to prevent OrbitControls to block page scrolling */}
            <Box
              sx={{
                backgroundColor: 'transparent',
                height: '100%',
                inset: 0,
                position: 'absolute',
                width: '100%',
              }}
            />
          </Stack>
        </Grid>
      </Grid>
    </HomepageSection>
  );
}
