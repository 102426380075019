import type { ReactElement } from 'react';
import type { SxProps, Theme } from '@mui/material';
import { styled } from '@mui/material/styles';

type PpIbanLargeCube = {
  className?: string;
  mobileStyle?: SxProps<Theme>;
};

const StyledCube = styled('img')<PpIbanLargeCube>(({ mobileStyle, theme }) => ({
  height: 'auto',
  // maxWidth: '550px',
  width: '30%',
  [theme.breakpoints.down('lg')]: {
    width: '30%',
  },
  [theme.breakpoints.down('sm')]: {
    position: 'relative',
    width: '50%',
    ...mobileStyle,
  },
}));

export function IbanLargeCube({ className, mobileStyle }: PpIbanLargeCube): ReactElement {
  return (
    <StyledCube
      alt="cube"
      className={className}
      mobileStyle={mobileStyle}
      src="https://res.cloudinary.com/noahapp/image/upload/v1708422731/Website/Homepage%20revamp%202024/digital-cube_6_1_h8zvnh.png"
    />
  );
}
