import type { ReactElement } from 'react';
import { styled } from '@mui/material/styles';

type PpIbanSmallCube = {
  bottom: number;
  left: string;
  width: string;
};

const StyledCube = styled('img')<PpIbanSmallCube>(({ bottom, left, theme, width }) => ({
  bottom: theme.spacing(bottom),
  left,
  position: 'absolute',
  width,
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));

export function IbanSmallCube({ bottom, left, width }: PpIbanSmallCube): ReactElement {
  return (
    <StyledCube
      alt="cube"
      bottom={bottom}
      left={left}
      src="https://res.cloudinary.com/noahapp/image/upload/v1708422731/Website/Homepage%20revamp%202024/digital-cube_6_1_h8zvnh.png"
      width={width}
    />
  );
}
